.modal {
  background-color: transparent !important;
  &--header {
    @include flex-center-justify-between;
    background-color: $ethera-gray-dark;
    padding: 7px 15px;
    color: $black;
    margin-bottom: 12px;
  }

  &--tag {
    display: inline-block;
    padding: 7px 15px;
    background-color: $ethera-gray-dark;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 200px;
    margin-bottom: 10px;

    & strong {
      white-space: nowrap;
    }
  }

  &--body {
    padding: 7px 20px !important;
    display: flex;
    justify-content: flex-start;
    @include flex-column;
  }

  &--footer {
    padding: 7px 20px !important;
    display: flex;
    justify-content: flex-start;
    @include flex-column;

    & button {
      margin-bottom: 10px;
    }

    &__save {
      background: $ethera-green !important;
      border: none !important;
      color: $black;
    }

    &__cancel {
      background: transparent !important;
      color: $black;
    }
  }

  &--serviceSection {
    background-color: #f9f9f9;
    margin-top: -1rem;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;

    padding: 1rem;

    &--services {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: .5rem;

      &__dot {
        height: 5px;
        width: 5px;
        background-color: #f9f9f9;
        border-radius: 50%;
        display: inline-block;
        outline: 4px solid $button-green;
        margin-right: 10px;
      }

      &__title {
        min-width: 55%;
      }

      &__code {
        min-width: 20%;
      }

      &__price {
        min-width: 20%;
        text-align: right;
      }
    }
  }
}
