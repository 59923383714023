.bookings-header {
  @include flex-center-start;
  @include flex-column;

  @include breakpoint-up(small) {
    @include column-reverse;
  }

  &--left {
    @include flex-center-start;
    @include flex-column;
    margin-bottom: 1rem;
    width: 100%;

    @include breakpoint-up(xSmall) {
      @include flex-row;

      & button {
        margin-left: 1rem;
      }
    }

    & button {
      width: 100%;
    }

    &__calendar {
      width: 100%;
      margin-bottom: 1rem;

      @include breakpoint-up(xSmall) {
        padding-right: 3rem;
      }
    }

    &__search {
      width: 100%;
    }

    @include breakpoint-up(xSmall) {
      & div:first-child {
        margin-right: 1rem;
      }
    }
  }

  &--selects {
    &--select-field{
      position: relative !important
    }
    @include flex-stretch-center;
    @include flex-column;
    width: -webkit-fill-available;

    @include breakpoint-up(small) {
      @include flex-row;
      column-gap: 10px;
    }

    @include breakpoint-up(large) {
      margin-bottom: 1rem;
    }
  }

  @include breakpoint-up(xxxMedLarge) {
    @include flex-row;
    align-items: center;

    &--left {
      @include flex-row;
      justify-content: flex-start;

      margin-bottom: 0;
      margin-right: 1rem;

      &__calendar {
        width: fit-content;
        min-width: 200px;
      }

      &__search {
        & div {
          margin-bottom: 0;
        }
      }
    }
  }
}
