.chevronLeft {
  margin: 0px 5px;
  cursor: pointer;
}

.appointment-stat-div {
  font-size: 12px;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 7px 10px;
  margin: 5px;

  & span {
    white-space: nowrap !important;
  }

  & p {
    margin: 0;
    white-space: nowrap;
    font-weight: bold;
    font-size: 15px;
  }
}

.appointments-export {
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  width: 65%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;

  @include breakpoint-down(large) {
    margin-top: 8px;
  }

  @include breakpoint-down(med) {
    width: 100%;
  }

  padding: 3px 0px;
  p {
    margin: 0 !important;
  }
  svg {
    margin-right: 5px;
  }
}

.AppointmentSelectors_left {
  font-size: 12px;

  &-invoices {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 4px 15px;
    cursor: pointer;

    & span {
      margin-left: 7px;
      white-space: nowrap;
      
    }
  }

  &-dates {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    background-color: #d0d8e5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    cursor: pointer;

    & svg {
      margin: 2px 15px 2px 2px;
      padding: 4px;
      background-color: #798caf;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
    }

    & p {
      white-space: nowrap;
      margin: 0;

    }
  }
}

.AppointmentSelectors_right {
  &--formGroup {
    & input {
      border-radius: 50px !important;
      -webkit-border-radius: 50px !important;
      -moz-border-radius: 50px !important;
      -ms-border-radius: 50px !important;
      -o-border-radius: 50px !important;
    }
  }
}

.whiteBorder {
  border-color: #fefefe !important;
}

.time-date-row {
  &__dark {
    color: #fefefe !important;
    border-color: #fefefe !important;
  }


  & input.form-control {
    padding: 5px 15px;
    border-radius: 25px !important;
    -webkit-border-radius: 25px !important;
    -moz-border-radius: 25px !important;
    -ms-border-radius: 25px !important;
    -o-border-radius: 25px !important;
  }

  & span {
    margin: 0px 7px !important;
  }
}

.appointment_popup_details {
  & span {
    &:first-child {
      margin-right: 10px;
    }
    padding: 3px 15px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  }
}

.activeClient {
  background-color: #fff9ef;
  border: 1px solid rgba(120, 62, 52, 0.4);
}

.button-close {
  color: #84412a !important;
  border: none !important;
  background-color: #e69c83 !important;
}

.appointment-popup-invoice {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0.2rem 0.8rem !important;
  padding: 0.6rem 0.8rem;
  &__left {
    & span {
      margin-left: 10px;
      font-size: 12px;
    }
  }
  &__right {
    margin-left: auto;
    padding-left: 5px;
    & a {
      color: gray !important;
      text-decoration: underline;
      font-size: 12px;

      &:hover {
        color: lightgray !important;
      }
    }
  }
}

.appointments-detail-tag {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: black;

  & span {
    margin-left: 7px;
  }
}

.clients__row {
  padding: 0rem 1.4rem;
  &--div {
    margin-right: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    background-color: #e2dbc8;
    color: black;
    margin-bottom: 5px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  &--edit {
    width: 30%;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    & span {
      margin-left: 5px;
      white-space: nowrap;
    }
  }
}

.services__row {
  padding: 0rem 1.4rem;
  &--div {
    white-space: nowrap;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    background-color: #e2dbc8;
    color: black;
    margin-bottom: 5px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
}

.smallScreenPopup {
  width: 65%;
}

.largeScreenPaddings {
  padding-right: 0 !important;
}

.bookings_popup_billing_total {
  & strong:first-child {
    margin-left: 1.4rem;
  }

  & strong:last-child {
    padding: 5px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #d0d8e5;
  }
}

// ******************************************************************************************************************
.appointment-header {
  @include flex-center-start;
  @include flex-column;

  @include breakpoint-up(small) {
    @include column-reverse;
  }

  @include breakpoint-up(large) {
    @include flex-row;
    align-items: center;
  }

  &--bottom_date {
    width: 100%;
    padding-right: 5px;
    margin-bottom: 1rem;

    @include breakpoint-up (xxxSmall) {
      padding-right: 1rem;
    }

    @include breakpoint-up(small) {
      width: fit-content;
      margin-bottom: 0;
      padding-right: 3rem;
    }

  }

  &--selects {
    @include flex-stretch-center;
    @include flex-column;
    width: -webkit-fill-available;

    &__button {
      width: 100;
      margin-left: 5px;
      margin-right: 5px;

      & button {
        width: 100%;
      }

    }


    @include breakpoint-up(small) {
      @include flex-row;
      justify-content: space-between;
    }

    @include breakpoint-up(large) {
      width: fit-content;
    }
  }
}
