.system-settings {
  &--adminACcounts {
    &__system {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @include flex-column;
      margin-bottom: 1rem;
      background-color: $ethera-system-settings-bg;
      border-radius: 4px;

      & p:first-child {
        padding: 2rem;
        text-align: center;
        align-self: center !important;
      }

      &--data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 400px;

        &__email {
          min-width: 280px;
        }
      }

      &--actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      & button {
        align-self: flex-end;
        margin: 2rem;
      }

      .table-responsive {
        width: 100% !important;
      }

      & table {
        & tbody {
          & tr {
            & td:first-child {
              border-left-width: 0 !important;
            }
            & td:last-child {
              border-right-width: 0 !important;
            }
          }
        }
      }
    }

    &__other {
      @include flex-start-center;
      @include flex-column;
      width: 100%;

      &--kiosk {
        width: 100%;
        background-color: $ethera-system-settings-bg;
        border-radius: 4px;
        margin-bottom: 1rem;

        & p:first-child {
          padding: 2rem;
          text-align: center;
        }
      }

      &--board {
        background-color: $ethera-system-settings-bg;
        border-radius: 4px;
        width: 100%;

        &__emails {
          @include flex-center-start;
          @include flex-column;
          overflow-x: hidden;

          & div:last-child {
            margin-top: 0.5rem;
          }

          @include breakpoint-up(xxSmall) {
            @include flex-row;
            align-items: center;
            justify-content: space-between;

            & div:last-child {
              margin-top: 0rem;
            }
          }
        }

        &__add {
          @include flex-center;
          padding: 2rem !important;
        }

        & p:first-child {
          padding: 2rem;
          text-align: center;
        }
      }

      @include breakpoint-between(medLg, xLarge) {
        @include flex-row;
        align-items: flex-start;
        justify-content: space-between;

        &--kiosk {
          margin-right: 1rem;
        }
      }
    }

    @include breakpoint-up(xxMedLarge) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include flex-row;

      &__system {
        margin-right: 1rem;
        width: 60%;
        &--data {
          min-width: 350px;

          &__email {
            min-width: 230px;
          }
        }
      }

      
      &__other {
        width: 40%;
      }
    }
  }
}
