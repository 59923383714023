.btn-large-transparent {
  padding: 0.5rem 1.5rem !important;

  background-color: transparent !important;
  border: none !important;
  text-align: left;
  &:active {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & strong {
    color: $ethera-golden;
  }
}

.add-more {
  background-color: $ethera-button-secondary !important;
  border: 1px solid rgba($ethera-text-color, 0.5) !important;
  color: $gray-800 !important;
  padding: 0.5rem 1.5rem !important;
  &:active {
    background-color: $ethera-button-secondary !important;
  }
  &:focus {
    background-color: $ethera-button-secondary !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}

.button-delete {
  background-color: $ethera-brick-dark !important;
  padding: $button-green-padding !important;
  border: none !important;
  position: relative;
  color: $white !important;

  &:active {
    background-color: $ethera-brick-dark !important;
  }
  &:focus {
    background-color: $ethera-brick-dark !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
}
.button-delete-styling {
  padding: $button-green-padding !important;
  position: relative;
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
}
.button-delete-credit {
  background-color: $ethera-brick-dark !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
  position: relative;
  color: $white !important;

  &:active {
    background-color: $ethera-brick-dark !important;
  }
  &:focus {
    background-color: $ethera-brick-dark !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
}

.button-primary {
  background-color: #f99b40 !important;
  padding: $button-green-padding !important;
  border: none !important;
  position: relative;
  color: $white !important;

  &:active {
    background-color: #f99b40 !important;
    color: $white !important;
  }
  &:focus {
    background-color: #f99b40 !important;
    color: $white !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
}

.button-gray {
  background-color: $ethera-btn-gray !important;
  border: 1px solid rgba($black, 0.5) !important;
  color: $gray-800 !important;
  padding: 0.5rem 1.5rem !important;
  &:active {
    background-color: $ethera-btn-gray !important;
  }
  &:focus {
    background-color: $ethera-btn-gray !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}

.btn-green {
  background-color: $button-green !important;
  color: white !important;
  border: none !important;
  padding: 0.5rem 1.5rem !important;
  &:active {
    background-color: $button-green !important;
  }
  &:focus {
    background-color: $button-green !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}

.btn-gold {
  background-color: #edce5b !important;
  color: $gray-700 !important;
  border: none !important;
  padding: 0.5rem 1.5rem !important;
  &:active {
    background-color: #edce5b !important;
  }
  &:focus {
    background-color: #edce5b !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}

.button-reset {
  background-color: $ethera-gray-light !important;
  border: 1px solid rgba($ethera-gray-text, 0.5) !important;
  color: $ethera-gray-text !important;
  white-space: nowrap !important;
  &:active {
    background-color: $ethera-gray-light !important;
  }
  &:focus {
    background-color: $ethera-gray-light !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}

button[disabled] {
  cursor: not-allowed !important;
  pointer-events: visible !important;
  opacity: 0.5 !important;
}
.button-ethera {
  background-color: $bg-ethera !important;
  padding: $button-green-padding !important;
  border: 1px solid rgba($ethera-text-color, 0.7) !important;
  color: $gray-700;
  position: relative;
  &:disabled {
    color: $black !important;
  }
  &:active {
    background-color: $bg-ethera !important;
    color: $gray-700;
  }
  &:focus {
    color: $gray-700;
    background-color: $bg-ethera !important;
  }
  &:hover {
    color: $gray-700;
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }

  &::before {
    content: '+';
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: $ether-calendar-active;
    color: $bg-ethera;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
}

.button-ethera-no-icon {
  background-color: $bg-ethera !important;
  padding: 0.4rem 1rem !important;
  border: 1px solid rgba($ethera-text-color, 0.7) !important;
  color: $gray-700;
  position: relative;
  &:disabled {
    color: $black !important;
  }
  &:active {
    background-color: $bg-ethera !important;
    color: $gray-700;
  }
  &:focus {
    color: $gray-700;
    background-color: $bg-ethera !important;
  }
  &:hover {
    color: $gray-700;
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}

.badge-button {
  padding: 8px 20px !important;
  background: #d0d8e5 !important;
  border: none;
  color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 20px !important;
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

// form group buttons
.form {
  &__group {
    margin-top: 15px !important;
    margin-left: -10px !important;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__input {
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    background-color: rgba(#fefefe, 0.5);
    border: none;
    font-family: 'Poppins', sans-serif;
    color: inherit;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #999;
    }

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(#000, 0.15);
      border-bottom: 3px solid #783e34;
    }

    &:focus:invalid {
      border-bottom: 3px solid #45556f;
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
    -webkit-transform: translateY(-4rem);
    -moz-transform: translateY(-4rem);
    -ms-transform: translateY(-4rem);
    -o-transform: translateY(-4rem);
  }

  &__radio-group {
    width: 49%;
    display: inline-block;
  }

  &__radio-input {
    visibility: hidden;
  }

  &__radio-label {
    font-size: 16px;
    cursor: pointer;
    position: relative;
    padding-left: 3.5rem;
  }

  &__radio-button {
    height: 2rem;
    width: 2rem;
    border: 3px solid #783e34;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;

    &::after {
      content: '';
      display: block;
      height: 1rem;
      width: 1rem;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #783e34;

      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;

      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);

      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
    }
  }

  &__radio-input:checked + &__radio-label &__radio-button {
    &::after {
      opacity: 1;
    }
  }
}

.button-success {
  @include flex-center;
  background-color: $button-green !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $button-green !important;
  }
  &:focus {
    background-color: $button-green !important;
  }
}

.button-white {
  @include flex-center;
  background-color: $white !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  color: $black !important;
  font-weight: 400;
  position: relative;
  font-size: 15px;

  &:active {
    background-color: $white !important;
  }
  &:focus {
    background-color: $white !important;
  }
}

.button-signature {
  @include flex-center;
  background-color: $ethera-blue !important;
  color: $white !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $ethera-blue !important;
  }
  &:focus {
    background-color: $ethera-blue !important;
  }
}

.button-danger {
  margin-left: 10px;
  @include flex-center;
  background-color: $danger !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $danger !important;
  }
  &:focus {
    background-color: $danger !important;
  }

  @include breakpoint-down(med) {
    margin-bottom: 1rem;
  }
}

.button-cancel {
  @include flex-center;
  background-color: $ethera-project-head !important;
  border: none !important;
  color: rgba($bg-dark, 0.6);
  position: relative;

  &:active {
    background-color: $ethera-project-head !important;
  }
  &:focus {
    background-color: $ethera-project-head !important;
  }
}
