.fc .fc-daygrid-more-link {
  font-weight: bold !important;
}

.fc-calendarIcon-button {
  color: $ethera-green !important;
}

.fc-event-title-container {
  padding: 3px !important;
}

.fc-daygrid-body {
  background-color: $white !important;
}

.fc .fc-daygrid-day-bottom {
  font-size: 0.5rem !important;

  @include breakpoint-up(xSmall) {
    font-size: 0.75rem !important;
  }
}

.fc-toolbar-title {
  min-width: 160px !important;

  @include breakpoint-down(450) {
    min-width: 210px !important;
  }

  @include breakpoint-up(med) {
    min-width: 230px !important;
  }
}

.event-small {
  overflow: hidden;
  @include breakpoint-up(medLg) {
    padding: 7px;
  }
}

.provider-avatar-calendar {
  margin: 5px 5px 5px 0px !important;
}

.datePicker-icon {
  background-color: $ether-calendar-active !important;
  display: inline-block !important;
  padding: 5px !important;
  border-radius: 50% !important;
  text-align: center !important;
  top: 20px !important;
  left: 10px;
}

.fc-toolbar {
  @include breakpoint-up(small) {
    &:last-child {
      padding-left: 1rem;
      margin-left: auto !important;
    }

    @include breakpoint-up(large11) {
      flex-wrap: nowrap !important;
    }
  }
}

.fc-col-header-cell-cushion {
  color: #697aa7 !important;
}

.title-color {
  color: #3e465b !important;
}

.fc .fc-toolbar .fc-toolbar-chunk:first-child {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  margin-left: 60px !important;

  & button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  margin-right: 15px !important;

  @include breakpoint-up(large11) {
    margin-right: 0 !important;
  }

  @include breakpoint-down(large) {
    margin-left: 50px !important;
  }

  @include breakpoint-down(xSmall) {
    & div {
      & button {
        padding: 0 !important;
      }
    }

    & button {
      padding: 0 !important;
    }
  }
}

.fc-toolbar-chunk {
  width: 100% !important;

  &:not(:first-child) {
    margin-top: 1rem;
    @include breakpoint-up(small) {
      margin-top: 0;
    }
  }

  @include breakpoint-down(small) {
    &:nth-child(2) {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }

  &:first-child {
    margin-left: 2.5rem !important;

    & h2 {
      font-size: 15px !important;
      white-space: nowrap !important;

      @include breakpoint-up(450) {
        font-size: 1.45rem !important;
      }

      @include breakpoint-up(small) {
        font-size: 16px !important;
      }

      @include breakpoint-up(xxLarge) {
        font-size: 1.45rem !important;
      }
    }
  }

  .fc-venueSelect-button,
  .fc-button-group {
    width: 100%;
  }

  @include breakpoint-up(xSmall) {
    width: 100% !important;

    .fc-button-group {
      width: fit-content !important;
      margin-left: auto !important;
    }

    &:first-child {
      flex-wrap: nowrap !important;
    }
  }

  @include breakpoint-up(med) {
    .fc-button-group:not(:last-child) {
      margin-left: 1rem !important;
    }

    .fc-button-group:last-child {
      margin-left: auto !important;
    }
  }

  @include breakpoint-up(large11) {
    width: fit-content !important;
  }
}

.fc-venueSelect-button {
  background-color: transparent !important;
  border: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 5rem !important;

  @include breakpoint-up(xSmall) {
    margin-bottom: 2rem !important;
  }

  @include breakpoint-up(small) {
    margin-bottom: 3rem !important;
  }

  @include breakpoint-up(med) {
    margin-bottom: 3.5rem !important;
  }

  @include breakpoint-up(large11) {
    margin-bottom: 1.5rem !important;
  }
}

.absolute-selectors {
  position: absolute;
  top: 7rem;

  @include breakpoint-up(xSmall) {
    top: 4.5rem;
  }

  @include breakpoint-up(large11) {
    top: 20px !important;
    transform: translateX(calc(100vw - 105%));
  }
}

.calendar-selectors {
  @include flex-end-center;
  @include flex-column;
  width: -webkit-fill-available !important;
  margin-inline: 15px;
  z-index: 20;
  & > div {
    z-index: 2;
    width: 100%;
  }
  & div {
    z-index: 200 !important;
    @include breakpoint-up(xxSmall) {
      z-index: 950 !important;
    }
  }
  @include breakpoint-up(xSmall) {
    @include flex-row;
    gap: 5px;
    & div {
      & div {
        margin-bottom: 0 !important;
      }
    }
  }

  @include breakpoint-up(med) {
    margin-inline: 0px;
    width: fit-content !important;
    transform: translateX(calc(100vw - 104%));
    & > div {
      width: fit-content;
    }
  }
  @include breakpoint-up(xLarge) {
    transform: translateX(calc(100vw - 100% - 270px));
  }
}

.fc .fc-daygrid-event-harness .fc-event {
  font-size: 10px !important;
  padding: 0 !important;
  text-overflow: clip !important;
  @include breakpoint-down(xSmall) {
    padding: 0 !important;
    font-size: 7px !important;
  }
}

.fc .fc-daygrid-day-frame {
  max-height: 70px !important;
}

.fc-venueSelect-button {
  color: 'red !important';
}

.fc .fc-timegrid-slot-label {
  height: 42px !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent !important;
}

.fc .fc-view-harness {
  @include breakpoint-up(xxxxLarge) {
    height: 85vh !important;
  }
}

.fc-day-disabled {
  background-color: transparent !important;
}
tr[role='row']:last-child .fc-day-disabled {
  display: none;
}
