.provider {
  &--addProvider {
    padding-top: 1rem!important;
  }
  &--addProvider__add {
    padding-top: 1rem!important;
  }
  &--header {
    @include flex-center-start;
    @include flex-column;
    &__editScreen {
      z-index: 1;
      width: 100%;
      @include breakpoint-down(small) {
        flex-direction: column;
      }
      &--title {
        flex-wrap: wrap;
        width: 100%;
        &__providerName {
          width: 100%;
          text-align: center;
        }

        @include breakpoint-up(xxxSmall) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &__name {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          &__providerName {
            margin-top: 0;
            width: fit-content;
            text-align: left;
          }
        }
      }
     
      &--button {
        display: flex;
        gap: 10px;
        background-color: red;
        align-items: center;
        @include breakpoint-down(small) {
          width: 100% !important ;
          justify-content: end !important;
        }
      }

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint-up(xSmall) {
        width: -webkit-fill-available;

        &--title {
          justify-content: flex-start;

          &__providerName {
            margin-left: 1rem;
          }
        }

        &--button {
          width: fit-content;
          margin-top: 0;
        }
      }
    }

    &__dropdown {
      width: 100%;

      & button {
        width: 100%;
        margin-bottom: 1rem;
      }
      &--selectors {
        position: relative !important;
      }

      @include breakpoint-up(xxSmall) {
        @include flex-start-center;
        &--selectors {
          width: 100%;
          &:not(:last-child) {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 5px;
          }
        }
      }
      @include breakpoint-down(xxSmall) {
        &--selectors {
          &:not(:last-child) {
          }
        }
      }

      @include breakpoint-up(medLg) {
        width: fit-content;
      }
    }

    &__search {
      width: 100%;

      & button {
        width: 100%;
        margin-bottom: 1rem;
      }

      @include breakpoint-up(xxSmall) {
        @include flex-start-center;

        & div {
          width: 100%;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      @include breakpoint-up(medLg) {
        width: fit-content;
      }

      @include breakpoint-up(large11) {
        & div:first-child {
          min-width: 150px !important;
        }
      }

      @include breakpoint-up(xLarge) {
        & div:first-child {
          min-width: 200px !important;
        }
      }
    }

    @include breakpoint-up(medLg) {
      @include flex-row;
      align-items: center;
      justify-content: space-between;

      .button-green {
        min-width: 150px !important;
        margin-bottom: 0 !important;
      }

      & div,
      & button {
        margin-bottom: 0 !important;
      }

      & button {
        & span {
          white-space: nowrap;
        }
      }
    }
  }
}

.modal-credit-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 21px 21px;
  & button:first-child {
    margin-right: 1rem;
  }
  @include breakpoint-down(xxxSmall) {
    padding-right: 10px;
  }
}
.status-selector div div div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.date-span {
  @include breakpoint-down(xxxSmall) {
    text-align: center;
    margin: 0.5rem 0 !important;
  }
}
.form-disabled:disabled {
  background-color: #efefef !important;
    opacity: 1;
}
.style-disabled div {
  background-color: #efefef !important;
    opacity: 1;
}
.css-1r4vtzz {
  background-color: #fff !important;
    border: none
}