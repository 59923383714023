.switch[type="checkbox"] {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .switch-label {
    min-width: 60px;
    min-height: 26px;
    display:block;
    background-color: #D0D8E5;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0 0 20px #D0D8E550;
  }
  .switch-label::after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
    position: absolute;
    border-radius: 70px;
    top: 3px;
    left: 3px;
    transition: 0.5s;
  }
  
  .switch:checked + .switch-label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
  
  .switch:checked + .switch-label {
    background-color: #6B9878;
  }
  
  .switch-label:active:after {
    width: 70px;
  }